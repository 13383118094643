<template>
  <Card data-menu-bg="true" adjustToMenu>
    <div class="container mb-10">
      <section class="mb-4">
        <h2 class="text-secondary">{{ article.name }}</h2>
        <div class="d-flex align-center mb-1">
          <p class="caption -no-margin text-grey-dark">{{ strapi?.ArticleNumberLabel }}: {{ article.part_number }}</p>
          <p class="paragraph-small -no-margin text-grey mx-2">|</p>
          <p class="caption -no-margin text-grey-dark">{{ strapi?.SupplierNumberLabel }}: {{ article.variant.vendor_part_number }}</p>
          <template v-if="article.is_end_of_life">
            <p class="paragraph-small -no-margin text-grey ms-2">|</p>
            <VIcon size="x-small" icon="far fa-info-circle" class="text-warning my-auto ms-2" />
            <p class="paragraph-small -no-margin text-warning ml-2 bold">{{ strapi?.EndOfLife }}</p>
          </template>
        </div>
      </section>
      <div class="grid h-100">
        <div class="grid-item slideshow -col-span-12 -col-span-md-4 -col-span-lg-5 pr-0 pr-lg-10">
          <SalesReasonBanner v-if="$isNotBlank(article.variant?.salesReason)" :salesReason="article.variant?.salesReason.name" />
          <ProductImageSwiperComponent :images="article.images" />
        </div>
        <div class="d-flex flex-column h-100 grid-item -col-span-md-7 -col-span-12 pl-0 pl-lg-5">
          <div class="d-flex flex-column flex-md-row w-100 h-100 gap-21 gap-md-41">
            <div class="order-1 d-flex flex-column justify-space-between w-100">
              <section class="d-flex flex-column gap-16">
                <div v-if="article.brand">
                  <p class="paragraph-small -no-margin bold">{{ strapi?.BrandLabel }}</p>
                  <p class="paragraph-small -no-margin">{{ article.brand.name }}</p>
                </div>
                <div v-for="(attribute, index) in focusedAttributes" :key="index">
                  <p class="paragraph-small -no-margin bold">{{ attribute.name }}</p>
                  <p class="paragraph-small -no-margin line-clamp-2 line-clamp-md-5">{{ attributeToString(attribute) }}</p>
                </div>
              </section>
              <div class="d-flex align-center mt-5 mt-md-0 cursor-pointer" @click="scrollDownToTabs">
                <Button iconButton class="bg-primary rounded-xl w-40px h-40px" color="white" iconSize="small" icon="fa-regular fa-arrow-down" ariaLabel="Scrolls down to all technical details" />
                <p class="paragraph -no-margin bold ml-4">All Technical Details</p>
              </div>
            </div>
            <div class="order-2 d-flex flex-column justify-space-between w-100">
              <section class="d-flex flex-column gap-8">
                <div>
                  <label class="paragraph-small bold">{{ strapi?.VariantsPluralLabel }}</label>
                  <VariantSelector v-model="selectedVariant" :items="article.variants" />
                </div>
                <div class="mt-2">
                  <p class="-no-margin bold">{{ strapi?.VariantDescriptionLabel }}</p>
                  <p v-html="article.variant.shop_description" />
                </div>
              </section>
              <div class="mt-4 mt-md-0 d-flex flex-column gap-10">
                <ReplacementProductOrContact :product="article" :priceInfo="priceInfo" />
                <div v-if="article.variant.hasPrices" class="d-flex rounded-lg bg-grey-background pa-3 gap-10">
                  <div v-for="row in article.variant.prices" :key="row.quantity" class="flex-basis-25">
                    <div class="w-fit-content ma-0-auto">
                      <p class="paragraph-small -no-margin">&ge; {{ row.quantity }}</p>
                      <p class="paragraph-small -no-margin bold" :class="{ 'bold sale-price-text-color': article.variant?.salesReason }">{{ $utils.moneyFormat(row.price) }}</p>
                    </div>
                  </div>
                </div>
                <ProductPageQuantityInput v-if="price" v-model:quantity="quantity" v-model:price="price" v-model:loading="loading" :priceDirty="priceDirty" :isOnSale="$isNotBlank(article.variant?.salesReason)" />
                <Button color="white" iconFirst class="bg-accent w-100" :icon="!article.variant.allowedForCheckout ? '' : 'fa-regular fa-plus'" :disabled="!article.variant.allowedForCheckout || priceDirty || cartLoading" :loading="cartLoading" @click="addToCart">{{ !article.variant.allowedForCheckout ? 'Not available for Checkout' : 'Add to Cart' }}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="tabs">
      <Tabs :article="article" />
    </div>
  </Card>
</template>

<script>
import ProductPageQuantityInput from './components/ProductPageQuantityInput.vue';
import VariantSelector from './components/VariantSelector.vue';
import Tabs from './components/Tabs.vue';
import ProductImageSwiperComponent from '@Components/ProductImageSwiperComponent.vue';
import SalesReasonBanner from '@Components/SalesReasonBanner.vue';
import { gsap } from 'gsap';
import { debounce } from '@Utils/default.js';
import ReplacementProductOrContact from '@/components/ReplacementProductOrContact.vue';

export default {
  name: 'ProductShow',
  components: { ProductPageQuantityInput, Tabs, ProductImageSwiperComponent, SalesReasonBanner, ReplacementProductOrContact, VariantSelector },
  data() {
    return {
      loading: false,
      cartLoading: false,
      ...this.$controller.data,
      price: null,
      priceDirty: false,
      quantity: 1,
      selectedVariant: this.$controller.data.article.variant.code
    };
  },
  computed: {
    focusedAttributes() {
      return this.article.attributes.slice(0, 3);
    },
    priceInfo() {
      if (this.article.variant?.hasPrices) {
        return 'prices';
      } else if (this.$isNotEmpty(this.article.replacementArticle)) {
        return 'replacement';
      } else {
        return 'contact';
      }
    }
  },
  watch: {
    selectedVariant: {
      async handler(variant) {
        this.article.variant = this.article.variants.find(v => v.code === variant);
        this.updateURLParameter('variant', variant);
        await this.calculatePrice();
      }
    },
    quantity: {
      async handler() {
        this.priceDirty = true;
        this.getPrice();
      }
    }
  },
  async created() {
    await this.calculatePrice();
  },
  methods: {
    getPrice: debounce(async function () {
      await this.calculatePrice();
    }, 400),

    async calculatePrice() {
      if (!this.article.variant.hasPrices) {
        this.price = null;
        return;
      }
      this.loading = true;
      setTimeout(async () => {
        const response = await this.post('calculate-price/', { variant: this.article.variant, quantity: this.quantity });
        if (!response) {
          this.loading = false;
          return;
        }
        this.price = response.price;
        this.loading = false;
        this.priceDirty = false;
      }, 300);
    },
    addToCart() {
      this.cartLoading = true;
      setTimeout(() => {
        this.$mitt.emit('cart:add', { partNumber: this.article.part_number, variantCode: this.article.variant.code, quantity: this.quantity });
        this.cartLoading = false;
      }, 600);
    },
    scrollDownToTabs() {
      gsap.to(window, { duration: 0.8, scrollTo: this.$refs.tabs.offsetTop });
    },
    attributeToString(attribute) {
      if (attribute.type === 'boolean') {
        return attribute.value ? 'Yes' : 'No';
      } else if (attribute.type === 'range') {
        return `${attribute.value[0]} - ${attribute.value[1]}`;
      } else if (attribute.type === 'numeric') {
        return attribute.value;
      } else {
        return attribute.value.join(', ');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.slideshow {
  border-right: 0;

  @include is-screen-lg() {
    border-right: 1px solid $color-grey-light;
  }
}

:deep(.mySwiper2) {
  margin-top: 0 !important;
}

:deep(.swiper-slide img) {
  object-fit: contain;
}

.sale-price-text-color {
  color: $color-secondary;
}
</style>
